<template>
  <div>
    <span style="font-size: 12px">概述：</span>
    <div class="coalblock" v-for="item in options" :key="item.title">
      <span style="font-size: 12px; color: #9196a1">{{ item.title }}</span>
      <!-- <br /> -->
      <span style="font-size: 24px;margin-left: 10px;">{{ item.num }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "overViewOfEquipment",
  props: ['tableData', 'total','totalMPNum'],
  watch: {
    tableData (newVal) {
      console.log("tableData this.tableDate"+this.tableData)
      console.log("tableData this.total"+this.total)
      this.options[0].num = this.total + "个";
      // let temp = 0;
      // for (let i = 0; i < this.tableData.length; i++) {
      //   temp = temp + this.tableData[i].measurePointCount
      //   console.log(this.tableData[i].measurePointCount)
      // }
      // this.options[1].num = temp + "个";
      this.options[1].num = this.totalMPNum + "个";
    }
  },
  data() {
    return {
      options: [{
        title: "附属设备总数",
        num: 0
      }, {
        title: "数据项总数",
        num: 0
      }]
    }
  },
  mounted() {
    console.log("mounted this.tableDate"+this.tableData)
    console.log("mounted this.total"+this.total)
    this.options[0].num = this.total + "个";
    // let temp = 0;
    // for (let i = 0; i < this.tableData.length; i++) {
    //   temp = temp + this.tableData[i].measurePointCount
    //   console.log(this.tableData[i].measurePointCount)
    // }
    // this.options[1].num = temp + "个";
    this.options[1].num = this.totalMPNum + "个";
  }
}
</script>

<style scoped>

</style>
